const envUrl = process.env.REACT_APP_ENVIRONMENT_URL ? `/${process.env.REACT_APP_ENVIRONMENT_URL}` : '';
const envUrlInvoices = process.env.REACT_APP_ENVIRONMENT_URL_INVOICES ? `/${process.env.REACT_APP_ENVIRONMENT_URL_INVOICES}` : '';
const subDomainEntry = process.env.REACT_APP_SUBDOMAIN_ENTRY ? `${process.env.REACT_APP_SUBDOMAIN_ENTRY}` : 'entry';
const subDomainCore = process.env.REACT_APP_SUBDOMAIN_CORE ? `${process.env.REACT_APP_SUBDOMAIN_CORE}` : 'core';
const entryBaseApi = `https://${subDomainEntry}.paxtoncloud.com${envUrl}/api/v1/`;
const invoicesBaseApi = `https://${subDomainCore}.paxtoncloud.com${envUrlInvoices}/api/v1/`;

export const Urls = {
  AccountInfo: `${entryBaseApi}`,
  HardwareNegotiate: (siteId?: string) => `${entryBaseApi}hardware/negotiate/${siteId}`,
  AddressBookNegotiate: `${entryBaseApi}addressbook/negotiate`,
  AddressBook: (siteId?: string) => (!siteId ? undefined : `${entryBaseApi}addressBook/${siteId}`),
  Companies: `${entryBaseApi}companies`,
  CompaniesTermsAndConditions: `${entryBaseApi}companies/termsAndConditions`,
  CompaniesTaxCertificate: `${entryBaseApi}companies/taxCertificate`,
  CompaniesVatNumber: `${entryBaseApi}companies/vatNumber`,
  CompaniesFields: `${entryBaseApi}companies/fields`,
  User: (entryUserId: string, companyId?: string, siteId?: string) => (!companyId || !siteId
    ? undefined
    : `${entryBaseApi}mobile/users/${entryUserId}?companyId=${companyId}&siteId=${siteId}`),
  Users: `${entryBaseApi}mobile/users`,
  UsersTotals: (siteId?: string) => (!siteId ? undefined : `${entryBaseApi}mobile/users/total?siteId=${siteId}`),
  UsersAddressBook: (siteId?: string) => (!siteId ? undefined : `${entryBaseApi}mobile/users/addressBook?siteId=${siteId}`),
  UsersNegotiate: `${entryBaseApi}mobile/users/negotiate`,
  ReinstateUser: (entryUserId: string, siteId: string) => `${entryBaseApi}mobile/users/reinstate/${siteId}/${entryUserId}`,
  ResendUserEmail: (entryUserId: string, siteId: string) => `${entryBaseApi}mobile/users/${siteId}/resend/email/${entryUserId}`,
  UsersWithSiteId: (siteId?: string) => (!siteId ? undefined : `${entryBaseApi}mobile/users?siteId=${siteId}`),
  AdminTermsAndConditions: `${entryBaseApi}/entry-admin/users/terms-and-conditions`,
  HardwareSummary: (siteId?: string) => (!siteId ? undefined : `${entryBaseApi}hardware/summary/${siteId}`),
  Site: (siteId?: string, companyId?: string) => (!siteId || !companyId
    ? undefined
    : `${entryBaseApi}sites/${siteId}?companyId=${companyId}`),
  Sites: `${entryBaseApi}sites`,
  SitesNegotiate: `${entryBaseApi}sites/negotiate`,
  SitesWithCompanyId: (companyId?: string) => (!companyId ? undefined : `${Urls.Sites}?companyId=${companyId}`),
  SitesSummary: (companyId?: string, search?: string) => (!companyId
    ? undefined
    : `${entryBaseApi}sites/summary/${companyId}${search ? `?search=${search}` : ''}`),
  IsSiteNameUnique: (companyId?: string, siteName?: string, siteId?: string) => (!companyId || !siteName
    ? undefined
    : `${entryBaseApi}sites/isNameUnique/${siteName}?companyId=${companyId}${siteId ? `&siteId=${siteId}` : ''}`),
  IsUserEmailNameUnique: (email?: string, siteId?: string, userId?: string) => (!siteId || !email
    ? undefined
    : `${entryBaseApi}mobile/users/isEmailUnique/${email}?siteId=${siteId}${userId ? `&userIdToExclude=${userId}` : ''}`),
  ConfigBind: `${entryBaseApi}config/bind`,
  Permissions: (userRole: string) => `${entryBaseApi}userRoles/${userRole}/permissions`,
  ChangePassword: `${entryBaseApi}directory/credential`,
  ConfigNegotiate: `${entryBaseApi}config/negotiate`,
  InternalAppsSignUp: `${entryBaseApi}internalApps/processCustomerRegistration`,
  InternalAppsValidateTaxCode: `${entryBaseApi}internalApps/admin/taxCode`,
  AdminsList: `${entryBaseApi}entry-admin/users/list`,
  AdminTotals: `${entryBaseApi}entry-admin/users/list/totals`,
  AdminUsers: `${entryBaseApi}entry-admin/users`,
  IsAdminEmailUnique: (email: string, userIdToExclude?: string) => (!email
    ? undefined
    : `${entryBaseApi}entry-admin/users/is-email-unique/${email}${userIdToExclude ? `?userIdToExclude=${userIdToExclude}` : ''}`),
  Admin: (adminUserId: string) => `${entryBaseApi}entry-admin/users/${adminUserId}`,
  AdminFirstTimeSignInStep: `${entryBaseApi}entry-admin/users/first-time-sign-in-setup`,
  AdminUserSendPasswordUpdatedEmail: (userId: string) => `${entryBaseApi}entry-admin/users/${userId}/send-password-updated-email`,
  GetWorldpayDataForOneTimePayment: (invoiceId: number) => `${entryBaseApi}internalApps/worldpayData/oneTimePayment/${invoiceId}`,
  GetWorldpayDataForAutomaticPayment: (customerReference: string) => `${entryBaseApi}internalApps/worldpayData/worldpayToken/${customerReference}`,
  WorldpayToken: (customerReference: string) => `${entryBaseApi}internalApps/worldpayToken/${customerReference}`,
  AdminUsersNegotiate: `${entryBaseApi}entry-admin/users/negotiate`,
  Notifications: `${entryBaseApi}notifications`,
  NotificationRead: (notificationId: string) => `${entryBaseApi}notifications/read/${notificationId}`,
  NotificationReadAll: `${entryBaseApi}notifications/readAll`,
  NotificationsUnreadCount: `${entryBaseApi}notifications/unreadCount`,
  NotificationsNegotiate: `${entryBaseApi}notifications/negotiate?userId=77bb0b6f-2126-4fc7-8204-e509a05bd8f8`,
  NotificationsNegotiateGroups: `${entryBaseApi}notifications/negotiateGroups`,
  Invoices: (customerReference: string, app: 'EntryApp' = 'EntryApp') => `${invoicesBaseApi}invoices/${customerReference}?application=${app}`,
  InvoicesTotalNumber: (customerReference: string, app: 'EntryApp' = 'EntryApp') => `${invoicesBaseApi}invoices/${customerReference}/totalNumber?application=${app}`,
  // eslint-disable-next-line max-len
  Invoice: (customerReference: string, invoiceId: number, app: 'EntryApp' = 'EntryApp') => `${invoicesBaseApi}invoices/${customerReference}/${invoiceId}?application=${app}`,
  // eslint-disable-next-line max-len
  InvoiceHandleOneTimePaymentSuccess: (customerReference: string, invoiceId: number, app: 'EntryApp' = 'EntryApp') => `${invoicesBaseApi}invoices/handleOneTimePaymentSuccess?application=${app}&invoiceId=${invoiceId}&customerReference=${customerReference}`,
  InternalAppsCustomerDetails: (customerReference: string) => `${entryBaseApi}internalApps/customerDetails/${customerReference}`,
  InternalAppsCutomerType: `${entryBaseApi}internalApps/authenticationApi/TypeOfCustomer`,
  InternalAppsWorldpayToken: (customerReference: string) => `${entryBaseApi}internalApps/worldpayToken/${customerReference}`,
  InternalAppsCustomerPricing: (customerReference: string) => `${entryBaseApi}internalApps/pricing/${customerReference}`,
  IsWhiteListed: (email: string) => `${entryBaseApi}connector/isWhiteListed?email=${encodeURIComponent(email)}`,
  B2CConnectorIsEmailExistsInAD: (email: string) => `${entryBaseApi}connector/isemailexists?email=${encodeURIComponent(email)}`,
};
