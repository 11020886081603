import html2canvas from 'html2canvas';
import ReactDOM from 'react-dom';
import jsPDF from 'jspdf';
import { DateTime } from 'luxon';
import { useCallback, useRef } from 'react';
import InvoicePdfPreview from './components/InvoicePdfPreview';
import { IInvoice, InvoiceStatus } from '../../shared/appUIFramework/appBackend/useInvoices';

export function getInvoiceStatusColorClassName(status: InvoiceStatus) {
  return {
    [InvoiceStatus.Unknown]: '',
    [InvoiceStatus.Unpaid]: 'app-color-secondary-yellow',
    [InvoiceStatus.Pending]: 'app-color-secondary-blue',
    [InvoiceStatus.Paid]: 'app-color-primary-green',
    [InvoiceStatus.Failed]: 'app-color-secondary-red',
    [InvoiceStatus.Overdue]: 'app-color-secondary-red',
  }[status];
}

export async function downloadInvoice(invoice: IInvoice) {
  const htmlPreviewDiv = window.document.getElementById('htmlPreview');
  if (!htmlPreviewDiv) {
    throw new Error('htmlPreview container is not found');
  }
  ReactDOM.render(<InvoicePdfPreview invoice={invoice} offscreen />, htmlPreviewDiv);

  // use timeout so pdf preview should be rendered before we are taking width and height of it before bounding client rect
  setTimeout(async () => {
    try {
      const canvas = await html2canvas(htmlPreviewDiv.children.item(0) as HTMLElement, {
        logging: false,
        allowTaint: true,
        windowWidth: htmlPreviewDiv.getBoundingClientRect().width,
        windowHeight: htmlPreviewDiv.getBoundingClientRect().height,
      });
      let { width } = canvas;
      let { height } = canvas;

      // set the orientation
      const jsPdfInstance = width > height
        // eslint-disable-next-line new-cap
        ? new jsPDF('l', 'px', [width, height])
        // eslint-disable-next-line new-cap
        : new jsPDF('p', 'px', [height, width]);

      // get the dimensions from the 'pdf' file itself
      width = jsPdfInstance.internal.pageSize.getWidth();
      height = jsPdfInstance.internal.pageSize.getHeight();
      jsPdfInstance.addImage(canvas, 'PNG', 0, 0, width, height, 'invoice-pdf', 'FAST');
      const generationDate = DateTime.fromJSDate(new Date()).toISODate();
      jsPdfInstance.save(`${invoice.invoiceId}-${generationDate}.pdf`);
    } finally {
      ReactDOM.unmountComponentAtNode(htmlPreviewDiv);
    }
  }, 500);
}

export function useFetchAndDownloadInvoice(getInvoice: (invoiceId: number) => Promise<IInvoice | null>) {
  const downloadStarted = useRef(false);

  return useCallback(async (invoiceId: number) => {
    try {
      if (downloadStarted.current) {
        return;
      }
      downloadStarted.current = true;
      const invoice = await getInvoice(invoiceId);
      if (!invoice) {
        return;
      }
      await downloadInvoice(invoice);
    } finally {
      downloadStarted.current = false;
    }
  }, [getInvoice]);
}

export function useDownloadInvoice(invoice?: IInvoice) {
  const download = useFetchAndDownloadInvoice(() => Promise.resolve(invoice!));
  return useCallback(async () => {
    if (invoice) {
      await download(invoice.invoiceId);
    }
  }, [download, invoice]);
}
